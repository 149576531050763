export const menuData = [
    { title: 'The Cabin', link: '/yadkinvalleywinecountry' },
    { title: 'Lodging', link: '/littleheavenbedandbreakfast' },
    { title: 'Weddings', link: '/weddings' },
    { title: 'Packages', link: '/packages' },
    { title: 'The Area', link: '/thearea' },
    { title: 'Special Events', link: '/specialevents' },
    { title: 'Boutique', link: '/boutique' },
    { title: 'Café', link: '/cafe' },
    { title: 'Our Story', link: '/story' }, 
    
]

